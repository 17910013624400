import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Menu from "./Menu";
import Info from "./Info";

function Restaurant() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [view, setView] = useState("menu"); // Default to 'menu' view

  useEffect(() => {
    // Get the view state from location state
    const currentState = location.state?.view || "menu";
    setView(currentState);
  }, [location.state]);

  // Function to switch views
  const switchView = (newView) => {
    setView(newView);
    navigate(location.pathname, { state: { view: newView }, replace: true });
  };

  // Determine which component to render
  let Component;
  switch (view) {
    case "menu":
      Component = Menu;
      break;
    case "info":
      Component = Info;
      break;
    default:
      Component = Menu;
  }

  return (
    <div>
      <button onClick={() => switchView("menu")}></button>
      <button onClick={() => switchView("info")}></button>
      <Component id={id} />
    </div>
  );
}

export default Restaurant;
