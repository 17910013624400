import React, { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";

const PopupExercise = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `mailto:business@urestaurants.app?subject=Registrazione&body=Email: ${email}`;
    onClose();
  };

  return (
    <div className="fixed mb-14 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-3/4 h-3/4 md:h-auto md:overflow-y-auto overflow-y-scroll">
        <div className="flex relative justify-between items-start">
          <h2 className="md:text-2xl text-lg font-bold mb-4">
            La tua avventura digitale inizia ora - completamente GRATUITA!
          </h2>
          <div className="absolute right-4 0 sm:right-[60px] sm:top-[110px]">
            <IoMdCloseCircle
              className="block md:hidden"
              size={24}
              onClick={onClose}
            />
            <IoMdCloseCircle
              className="hidden md:block"
              size={35}
              onClick={onClose}
            />
          </div>
        </div>
        <p className="mb-4">
          Registrati oggi e scopri i benefici esclusivi per il tuo locale:
        </p>
        <ul className="list-decimal list-inside mb-4">
          <li>
            Prenotazioni Immediate: Semplifica le prenotazioni e riduci i tempi
            di attesa. La nostra app intuitiva rende tutto più veloce e facile!
          </li>
          <li>
            Menu Interattivo: Attira più clienti con un menu digitale che
            permette ricerche avanzate sugli ingredienti. Personalizza le
            offerte in base alle esigenze e ai gusti personali dei tuoi ospiti.
          </li>
          <li>
            Esperienza Utente Unica: Offri ai tuoi clienti un'esperienza senza
            precedenti - dalla prenotazione alla selezione del menu.
          </li>
          <li>
            Maggiore Visibilità: Aumenta la tua visibilità e raggiungi un
            pubblico più ampio. Siamo qui per mettere in mostra il tuo locale!
          </li>
          <li>
            Nessun Costo Iniziale: Approfitta di tutte queste funzionalità senza
            alcuna spesa. La nostra piattaforma è completamente gratuita per te
            in questa fase iniziale!
          </li>
        </ul>

        <p className="mb-4">
          Unisciti a noi ora e trasforma il modo in cui i clienti interagiscono
          con il tuo ristorante!
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full mb-4 px-4 py-2 text-blue-500 border-none outline-none"
            required
          />
          <button
            type="submit"
            className="w-full py-2 bg-blue-500 text-white rounded-lg"
          >
            Invia
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupExercise;
