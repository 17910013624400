import React, { useCallback, useEffect, useState } from "react";
import { get, ref as dbRef } from "firebase/database";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { database, storage } from "../utils/firebase";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { AiOutlineDown } from "react-icons/ai";
import { languages } from "../utils/languages";
import Footer from "../utils/footer";

const Menu = () => {
  const { id } = useParams();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Pizze");
  const [loading, setLoading] = useState(true);
  const [toggledItems, setToggledItems] = useState({});
  const [categoryImages, setCategoryImages] = useState({});
  const [languageOpen, setLanguageOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("it");

  const fetchImageUrl = async (imagePath) => {
    const imageRef = storageRef(storage, imagePath);
    try {
      return await getDownloadURL(imageRef);
    } catch (error) {
      console.error("Error fetching image URL:", error);
      return "";
    }
  };

  const getTranslatedCategoryTitle = (category, data, selectedLanguage) => {
    return (
      data.find((item) => item.sezione === category)?.[
        `sezione!${selectedLanguage}`
      ] || category
    );
  };

  const hasSubCategories = filteredData
    .filter((item) => item.sezione === selectedCategory)
    .some((item) => item.subCategory);

  // Function to group items by subCategory
  const groupBySubCategory = (items) => {
    return items.reduce((groups, item) => {
      const category = item.subCategory || "";
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(item);
      return groups;
    }, {});
  };

  const filterData = useCallback(() => {
    let filtered = data;

    if (selectedCategory !== "all") {
      filtered = filtered.filter((item) => item.sezione === selectedCategory);
    }

    if (searchQuery) {
      filtered = filtered.filter((item) =>
        [
          item["000-Nome"],
          item["000-Nome!en"],
          item["ingredienti"],
          item["ingredienti!en"],
        ]
          .filter(Boolean)
          .some((field) =>
            field.toLowerCase().includes(searchQuery.toLowerCase())
          )
      );
    }

    setFilteredData(filtered);
  }, [data, selectedCategory, searchQuery]);

  useEffect(() => {
    if (id) {
      const dataRef = dbRef(database, `${id}`);
      get(dataRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const dataVal = snapshot.val();
            const items = dataVal.Items || {};
            const dataArray = Object.entries(items).map(
              ([itemId, itemData]) => ({
                id: itemId,
                ...itemData,
              })
            );

            setData(dataArray);

            const uniqueCategories = Array.from(
              new Set(dataArray.map((item) => item.sezione))
            );
            setCategories([...uniqueCategories]);

            setFilteredData(dataArray);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, selectedLanguage]);

  useEffect(() => {
    const fetchCategoryImages = async () => {
      const imagePromises = categories.map(async (category) => {
        const imagePath = `sectionImages/${
          category.charAt(0).toUpperCase() + category.slice(1)
        }.jpeg`;
        const imageUrl = await fetchImageUrl(imagePath);
        return { category, imageUrl };
      });

      const images = await Promise.all(imagePromises);
      const imageMap = images.reduce((acc, { category, imageUrl }) => {
        acc[category] = imageUrl;
        return acc;
      }, {});
      setCategoryImages(imageMap);
    };

    if (categories.length > 0) {
      fetchCategoryImages();
    }
  }, [categories]);

  useEffect(() => {
    filterData();
  }, [selectedCategory, searchQuery, data, filterData]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 6,
          initialSlide: 0,
        },
      },
    ],
  };

  // const handleLanguage = (id) => {
  //   setToggledItems((prev) => ({
  //     ...prev,
  //     [id]: !prev[id],
  //   }));
  //   setActiveItemId(id === activeItemId ? null : id); // Toggle off if clicked again
  // };

  const handleLanguage = (id) => {
    setToggledItems({ [id]: !toggledItems[id] });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const groupedData = hasSubCategories
    ? groupBySubCategory(
        filteredData.filter((item) => item.sezione === selectedCategory)
      )
    : null;

  var settingsD = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleLanguageChange = (languageSymbol) => {
    setSelectedLanguage(languageSymbol);
    setToggledItems((prev) => ({
      ...prev,
    }));
  };

  const categoryTitle = getTranslatedCategoryTitle(
    selectedCategory,
    data,
    selectedLanguage
  );

  const fontSize = categoryTitle.length > 10 ? "text-xl" : "text-3xl";

  return (
    <div>
      <nav className="info flex items-center px-6 py-3 fixed top-0 w-full z-30 bg-white">
        <div className="md:w-[76%] w-full mx-auto">
          {/* Centered content wrapper */}
          <div className="flex items-center justify-between w-full">
            {/* Logo */}
            <div
              className={`${fontSize} w-[550px] font-bold font-inter text-black`}
            >
              {categoryTitle}
            </div>

            {/* Search and Language Controls */}
            <div className="relative flex items-center gap-4 text-2xl w-full max-w-xl">
              <div className="relative w-full -mb-2">
                {searchOpen && (
                  <input
                    style={{ backgroundColor: "#E4E3E8" }}
                    type="text"
                    placeholder="Pizza, tomato"
                    className="py-1 px-2 pr-8 text-ellipsis text-[15px] w-[100px] md:w-full rounded placeholder:text-[17px] text-black -mr-4 focus:outline-none focus:ring-0 "
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                )}
                {searchQuery && (
                  <button
                    className="absolute -right-3 top-2 p-1 text-gray-600 hover:text-black"
                    onClick={() => setSearchQuery("")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
              </div>

              <button
                className="ml-3"
                onClick={() => setSearchOpen(!searchOpen)}
              >
                <FaSearch />
              </button>

              <div className="relative">
                <button
                  className="flex items-center gap-2 text-black"
                  onClick={() => setLanguageOpen(!languageOpen)}
                >
                  <img
                    src={
                      languages.find((lang) => lang.symbol === selectedLanguage)
                        ?.flag
                    }
                    alt={selectedLanguage}
                    className="w-6 h-6 rounded-full"
                  />
                  <AiOutlineDown size={24} />
                </button>
                {languageOpen && (
                  <ul className="absolute right-0 mt-2 w-48 bg-gray-800 text-white rounded shadow-lg z-10">
                    {languages.map((language, index) => (
                      <li
                        key={index}
                        className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
                        onClick={() => {
                          handleLanguageChange(language.symbol);
                          setLanguageOpen(false);
                        }}
                      >
                        <img
                          src={language.flag}
                          alt={language.name}
                          className="w-6 h-6 rounded-full mr-2"
                        />
                        {language.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Category Selection */}
      <div className="md:flex hidden flex-col justify-center items-center mb-4 mt-10">
        <Slider {...settingsD} className="w-[75%] ">
          {categories.map((category) => (
            <div key={category}>
              <div
                onClick={() => setSelectedCategory(category)}
                className="cursor-pointer"
              >
                <div className="relative w-full">
                  <img
                    className="object-cover w-[95%] h-[160px]  rounded-lg"
                    src={
                      categoryImages[category] || "https://via.placeholder.com"
                    }
                    alt={category}
                  />
                </div>
                <h4 className="text-xl font-inter mt-2">
                  {getTranslatedCategoryTitle(category, data, selectedLanguage)}
                </h4>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="md:hidden flex justify-start items-center mb-4 mt-10 z-0 overflow-hidden">
        <Slider {...settings} className="w-[95%]">
          {categories.map((category) => (
            <div
              key={category}
              className="flex justify-center items-center pr-2"
            >
              <div className="flex flex-col items-start w-full">
                <div
                  onClick={() => setSelectedCategory(category)}
                  className="cursor-pointer flex flex-col items-start gap-6 w-full"
                >
                  <img
                    className="image-size w-full rounded-2xl"
                    src={
                      categoryImages[category] ||
                      "https://via.placeholder.com/150"
                    }
                    alt={category}
                  />
                </div>
                <h4 className="text-[16px] mt-1 font-inter text-left">
                  {getTranslatedCategoryTitle(category, data, selectedLanguage)}
                </h4>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Menu */}
      <div className="mb-20 mt-5">
        {/* Menu with sucatgories */}

        {hasSubCategories
          ? Object.keys(groupedData).map((subCategory, index) => (
              <div key={subCategory}>
                <div className="flex justify-center items-center">
                  <h3
                    className={`text-[14px] pl-4 fony-inter md:w-[75%] uppercase text-gray-500 w-[95%] mb-2 ${
                      index === 0 ? "" : "pt-2"
                    }`}
                  >
                    {subCategory.charAt(0).toUpperCase() + subCategory.slice(1)}
                  </h3>
                </div>

                {groupedData[subCategory].map((item, index) => {
                  return (
                    <div
                      className={`flex justify-center items-center  `}
                      key={item.id}
                    >
                      <div
                        onClick={() => handleLanguage(item.id)}
                        className={` md:w-[75%] w-[95%] px-4 py-2 flex justify-between gap-5 items-start border-b-[1px] border-gray-300 ${
                          toggledItems[item.id] ? "bg-gray-400" : "bg-white"
                        } ${index === 0 ? "rounded-t-lg" : ""} ${
                          index === groupedData[subCategory].length - 1
                            ? "rounded-b-lg"
                            : ""
                        }`}
                      >
                        {/* Product Title */}
                        <div
                          className={`text-[16px] w-full font-inter text-gray-800}`}
                        >
                          <div>
                            {toggledItems[item.id]
                              ? item[
                                  selectedLanguage === "it"
                                    ? "000-Nome!en"
                                    : "000-Nome"
                                ] ?? item["000-Nome"] // Fallback to default if translation is missing
                              : item[
                                  selectedLanguage === "it"
                                    ? "000-Nome"
                                    : `000-Nome!${selectedLanguage}`
                                ] ?? item["000-Nome"]}{" "}
                            {/* Fallback if no translation */}
                          </div>
                          <div className="text-sm font-inter text-gray-500">
                            {toggledItems[item.id]
                              ? item[
                                  selectedLanguage === "it"
                                    ? "ingredienti!en"
                                    : "ingredienti"
                                ] ?? item["ingredienti"] // Fallback for ingredients
                              : item[
                                  selectedLanguage === "it"
                                    ? "ingredienti"
                                    : `ingredienti!${selectedLanguage}`
                                ] ?? item["ingredienti"]}{" "}
                            {/* Fallback for ingredients */}
                          </div>
                        </div>

                        {/* Product Price */}
                        <div className="text-lg md:w-full font-inter w-[30%] flex flex-col items-end text-gray-800">
                          <p className="flex gap-1">
                            <span>€ </span>
                            <span> {item.priceD ? `${item.priceD}` : ""}</span>
                          </p>
                          {item.priceD && item.priceM ? (
                            <div className="text-sm font-inter text-gray-800">
                              <p className="text-sm font-inter text-gray-800">
                                <span>€ </span>
                                <span>
                                  {item.priceM ? `${item.priceM}` : ""}
                                </span>
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))
          : filteredData.map((item, index) => {
              return (
                <div className="flex justify-center items-center" key={item.id}>
                  <div
                    onClick={() => handleLanguage(item.id)}
                    className={` md:w-[75%] w-[95%] px-4 py-2 flex justify-between gap-5 items-start border-b-[1px] border-gray-300 ${
                      toggledItems[item.id] ? "bg-gray-400" : "bg-white"
                    } ${index === 0 ? "rounded-t-lg" : ""} ${
                      index === filteredData.length - 1 ? "rounded-b-lg" : ""
                    }`}
                  >
                    {/* Product Title */}
                    <div
                      className={`text-[16px] w-full font-inter text-gray-800}`}
                    >
                      <div>
                        {toggledItems[item.id]
                          ? item[
                              selectedLanguage === "it"
                                ? "000-Nome!en"
                                : "000-Nome"
                            ] ?? item["000-Nome"] // Fallback to default if translation is missing
                          : item[
                              selectedLanguage === "it"
                                ? "000-Nome"
                                : `000-Nome!${selectedLanguage}`
                            ] ?? item["000-Nome"]}{" "}
                        {/* Fallback if no translation */}
                      </div>
                      <div className="text-sm font-inter text-gray-500">
                        {toggledItems[item.id]
                          ? item[
                              selectedLanguage === "it"
                                ? "ingredienti!en"
                                : "ingredienti"
                            ] ?? item["ingredienti"] // Fallback for ingredients
                          : item[
                              selectedLanguage === "it"
                                ? "ingredienti"
                                : `ingredienti!${selectedLanguage}`
                            ] ?? item["ingredienti"]}{" "}
                        {/* Fallback for ingredients */}
                      </div>
                    </div>

                    {/* Product Price */}
                    <div className="text-lg md:w-full font-inter w-[30%] flex flex-col items-end text-gray-800">
                      {item.priceD ? `€ ${item.priceD}` : ""}
                      {item.priceD && item.priceM ? (
                        <div className="text-sm font-inter text-gray-800">
                          {item.priceM ? `€ ${item.priceM}` : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>

      <Footer id={id} />
    </div>
  );
};

export default Menu;
