import it from "../media/flags/it.png";
import en from "../media/flags/en.png";
import fr from "../media/flags/fr.png";
import es from "../media/flags/es.png";
import de from "../media/flags/de.png";
import ru from "../media/flags/ru.png";
import zh from "../media/flags/zh.png";
import pl from "../media/flags/pl.png";

// these are the languages
export const languages = [
  { name: "Italiano", flag: `${it}`, symbol: "it" },
  { name: "Inglese", flag: `${en}`, symbol: "en" },
  { name: "Francese", flag: `${fr}`, symbol: "fr" },
  { name: "Spagnolo", flag: `${es}`, symbol: "es" },
  { name: "Tedesco", flag: `${de}`, symbol: "de" },
  { name: "Russo", flag: `${ru}`, symbol: "ru" },
  { name: "Cinese", flag: `${zh}`, symbol: "zh" },
  { name: "Polacco", flag: `${pl}`, symbol: "pl" },
];
